import firebase from "firebase/app";
import "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY_OC,
  projectId: process.env.VUE_APP_FB_PROJECT_ID_OC,
  appId: process.env.VUE_APP_FB_APP_ID_OC,
};
const outercloud = firebase.initializeApp(firebaseConfig, "outercloud");

// use functions emulator
const functions = firebase.functions(outercloud);
// functions.useEmulator("localhost", "5001");

export default outercloud;
