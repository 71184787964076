<template>
  <Home
    :loading="loading"
    :isInvalid="isInvalid"
    :createDate="createDate"
    :by="by"
    :files="files"
  />
</template>

<script>
import Home from "@/components/Home.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Home,
  },

  props: {},

  data() {
    return {
      loading: false,
      isInvalid: false,
      createDate: "",
      by: "",
      files: {},
    };
  },

  computed: {
    ...mapState(["ipDetails"]),
  },

  watch: {},

  created() {
    // Here fetch v2 data
    this.loading = true;

    Promise.all([this.getDataFromOC2(), this.getDataFromFalconi()])
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.loading = false;
      });
  },

  mounted() {},

  methods: {
    ...mapActions(["getFilesFromOC2", "getDataFromFalconi"]),

    async getDataFromOC2() {
      const data = await this.getFilesFromOC2({
        downloadLinkId: this.$route.params.id,
      });
      const { signature, createdAt, files } = data;

      this.by = signature;
      this.createDate = new Date(createdAt._seconds * 1000)
        .toLocaleDateString()
        .replaceAll("/", " . ");
      this.files = files;
    },
  },
};
</script>

<style lang="scss" scoped></style>
