import Vue from "vue";
import VueRouter from "vue-router";
import V1 from "../views/V1.vue";
import V2 from "../views/V2.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/v2/:id?",
    name: "V2",
    component: V2,
  },
  {
    path: "/:id?",
    name: "V1",
    component: V1,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
