import Vue from "vue";
import Vuex from "vuex";
import falconiFirebase from "@/helpers/falconiFirebase";
import OCFirebase from "@/helpers/OCFirebase";
import axios from "axios";

import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    falconiLogo: "",
    markers: [],
    ipDetails: {},
  },

  mutations: {
    setIpDetails(state, ipDetails) {
      state.ipDetails = ipDetails;
    },
    setFalconiLogo(state, falconiLogo) {
      state.falconiLogo = falconiLogo;
    },
    setMarkers(state, markers) {
      state.markers = markers;
    },
  },

  actions: {
    async getIpDetails({ commit }) {
      const ipDetailsUrl = `https://extreme-ip-lookup.com/json/?key=${process.env.VUE_APP_FB_IP_KEY}`;

      return axios.get(ipDetailsUrl).then((response) => {
        const clientDatetime = new Date();
        const clientDatetimeString = clientDatetime.toUTCString();
        const ipDetails = {
          dateTime: clientDatetimeString,
          city: response.data.city,
          country: response.data.country,
          countryCode: response.data.countryCode,
          isp: response.data.isp,
          lat: response.data.lat,
          lon: response.data.lon,
          org: response.data.org,
          ip: response.data.query,
          region: response.data.region,
        };

        commit("setIpDetails", ipDetails);

        return ipDetails;
      });
    },

    getDataFromFalconi({ commit }) {
      return (
        falconiFirebase
          .auth()
          .signInAnonymously()
          // eslint-disable-next-line arrow-body-style
          .then(() => {
            return Promise.all([
              falconiFirebase
                .firestore()
                .collection("settings")
                .doc("headerAndFooterSettings")
                .get()
                .then((doc) => {
                  commit("setFalconiLogo", doc.data().falconiLogo.downloadURL);
                }),

              falconiFirebase
                .firestore()
                .collection("projects")
                .get()
                .then((snapshot) => {
                  const projects = snapshot.docs
                    .map((el) => ({
                      ...el.data(),
                      coors: {
                        lat: el.data().lat ? parseFloat(el.data().lat) : "",
                        lng: el.data().lng ? parseFloat(el.data().lng) : "",
                      },
                      projectAddress: el.data().addr,
                    }))
                    .filter((el) => !el.isTestSite && !el.disabled);

                  const markers = projects.map((el) => ({
                    position: {
                      lat: el.lat ? el.lat : parseFloat(el.coors.lat),
                      lng: el.lng ? el.lng : parseFloat(el.coors.lng),
                    },
                    infoText: `PROJECT#${el.id} ${el.projectName} @ ${el.projectAddress}`,
                    id: el.id,
                    icon: "https://outercloud-icons.s3.us-west-2.amazonaws.com/pin.svg",
                  }));
                  commit("setMarkers", markers);
                })
                .catch((error) => console.error(error)),
            ]);
          })
      );
    },

    async getFilesFromOC2({ state, dispatch }, { downloadLinkId }) {
      await dispatch("getIpDetails");
      const response = await OCFirebase.functions().httpsCallable(
        "storage-getDownloadLinkFilesAndAddView"
      )({
        downloadLinkId,
        ipDetails: state.ipDetails,
      });

      return response.data;
    },
  },
});
