<template>
  <div class="dim-box flex-column fade-in">
    <div v-if="files.length" id="files-and-buttons">
      <div class="flex mb-20 slide-in-left" v-for="(file, index) in files" :key="index">
        <div class="file flex-5">
          <img class="file-icon mr-20" :src="iconPath(file)" />
          <div class="file-name-container mr-10">
            <span class="file-name">{{ file.fullName ? file.fullName : file.name }}</span>
            <span v-if="file.size" class="secondary-text file-size">
              {{ file.size !== "0" ? file.size : "" }}
            </span>
          </div>
        </div>
        <button
          class="my-button download-button"
          :title="getTitle(index)"
          @click="downloadSingle(file, index)"
          :disabled="isDownloading[index] || isDownloadingAll"
        >
          <p v-if="isDownloading[index]">{{ progressBars[index] }} %</p>

          <svg
            v-else-if="downloaded[index] || isDownloadedAll"
            class="slideDown"
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#e8eaed"
          >
            <path
              d="M482-160q-134 0-228-93t-94-227v-7l-64 64-56-56 160-160 160 160-56 56-64-64v7q0 100 70.5 170T482-240q26 0 51-6t49-18l60 60q-38 22-78 33t-82 11Zm278-161L600-481l56-56 64 64v-7q0-100-70.5-170T478-720q-26 0-51 6t-49 18l-60-60q38-22 78-33t82-11q134 0 228 93t94 227v7l64-64 56 56-160 160Z"
            />
          </svg>

          <svg
            v-else
            class="slideDown"
            xmlns="http://www.w3.org/2000/svg"
            height="36px"
            viewBox="0 -960 960 960"
            width="36px"
            fill="#e8eaed"
          >
            <path
              d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"
            />
          </svg>

          <!-- <img v-else src="@/assets/colored-download.svg" class="slideDown" /> -->
        </button>
      </div>
    </div>
    <div v-else class="not-found-container">
      <NotFound />
    </div>
    <div v-if="files.length > 1" class="flex align-items-center mt-auto w-100 flex-1">
      <div class="button mt-auto fade-in-bottom w-100 pr-20">
        <div
          class="flex mb-20 slide-in-left w-100 mt-20 pointer-cursor user-select-none"
          :class="{ 'disable-click': isDownloadingAll || isDownloading.some(Boolean) }"
          @click="downloadMultipleFiles()"
        >
          <div class="file flex flex-5">
            <h3 class="text-center h-100 flex-1 m-0 download-all-text">Download All</h3>
          </div>
          <button
            class="my-button download-button download-all-btn"
            :title="getDownloadAllTitle()"
            :disabled="isDownloading.some(Boolean)"
          >
            <svg
              v-if="isDownloadedAll"
              class="slideDown"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#e8eaed"
            >
              <path
                d="M482-160q-134 0-228-93t-94-227v-7l-64 64-56-56 160-160 160 160-56 56-64-64v7q0 100 70.5 170T482-240q26 0 51-6t49-18l60 60q-38 22-78 33t-82 11Zm278-161L600-481l56-56 64 64v-7q0-100-70.5-170T478-720q-26 0-51 6t-49 18l-60-60q38-22 78-33t82-11q134 0 228 93t94 227v7l64-64 56 56-160 160Z"
              />
            </svg>

            <svg
              v-else
              width="21"
              height="29"
              viewBox="0 0 21 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="slideDown"
            >
              <path
                d="M3.45232 28.3691C2.76545 28.3691 2.17744 28.1245 1.6883 27.6354C1.19916 27.1462 0.95459 26.5582 0.95459 25.8714V22.1248H3.45232V25.8714H18.4387V22.1248H20.9365V25.8714C20.9365 26.5582 20.6919 27.1462 20.2028 27.6354C19.7136 28.1245 19.1256 28.3691 18.4387 28.3691H3.45232Z"
                fill="white"
              />
              <path
                d="M9.69651 18.5655L6.44946 15.3185L5.01196 17.1293L10.9454 23.3737L17.1897 17.1293L15.4413 15.3185L12.1942 18.5655L10.9454 19.7445L9.69651 18.5655Z"
                fill="white"
              />
              <path
                d="M9.69651 11.1131L6.44946 7.86603L5.01196 9.67688L10.9454 15.9212L17.1897 9.67688L15.4413 7.86603L12.1942 11.1131V0.934814H9.69651V11.1131Z"
                fill="white"
              />
            </svg>
          </button>
          <div class="blur"></div>
        </div>
      </div>
    </div>
    <p class="pb-20 mt-auto text-align-center white">
      A proprietary application developed by EZ Fire!
    </p>
  </div>
</template>

<script>
import JSZip from "jszip";
import axios from "axios";
import NotFound from "./NotFound.vue";

export default {
  name: "Downloads",

  components: {
    NotFound,
  },

  props: {
    files: {
      type: Array,
      required: true,
    },

    isInvalid: {
      type: Boolean,
      required: false,
    },

    projectAlias: {
      type: String,
      required: true,
    },

    downloaded: {
      type: Array,
      required: true,
    },

    isDownloadedAll: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isDownloading: [],
      progressBars: [],
      isDownloadingAll: false,
    };
  },

  mounted() {
    this.progressBars = this.files.map(() => 0);
    this.isDownloading = this.files.map(() => false);
  },

  methods: {
    getDownloadAllTitle() {
      if (this.isDownloading.some(Boolean)) {
        return "Downloading...";
      }
      return this.isDownloadedAll ? "Re-download All" : "Download All";
    },

    getTitle(index) {
      if (this.isDownloading[index]) {
        return "Downloading...";
      }
      return this.downloaded[index] ? "Re-download" : "Download";
    },

    axiosDownload(url, index) {
      return axios({
        url,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.$set(this.progressBars, index, percentCompleted);
          if (progressEvent.loaded === progressEvent.total) {
            this.$set(this.isDownloading, index, false);
            this.$emit("update-downloaded", { index, value: true });
          }
        },
      });
    },

    downloadViaHref(urlObject, name) {
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlObject);
    },

    async downloadSingle(file, index) {
      try {
        this.$set(this.isDownloading, index, true);
        const response = await this.axiosDownload(file.url, index);
        const urlObject = window.URL.createObjectURL(new Blob([response.data]));
        this.downloadViaHref(urlObject, file.fullName);
      } catch (error) {
        console.error(error);
        this.$emit("update-downloaded", { index, value: false });
      }
    },

    async downloadMultipleFiles() {
      this.isDownloadingAll = true;
      this.isDownloading = this.files.map(() => true);

      const zip = new JSZip();
      await Promise.all(
        this.files.map(async ({ url, fullName }, index) => {
          const response = await this.axiosDownload(url, index);
          zip.file(fullName, response.data);
        })
      );

      // Generate the zip file and initiate the download
      const zipData = await zip.generateAsync({ type: "blob" });
      const urlObject = URL.createObjectURL(zipData);

      this.downloadViaHref(urlObject, "files.zip");
      this.$emit("update-downloaded-all");
      this.isDownloadingAll = false;
    },

    iconPath(file) {
      const path = require.context("../assets/file-type", false, /\.svg$/);
      const extension = file.fullName?.split(".").pop() || file.extension;
      return path(`./${this.icon(extension.toLowerCase())}`);
    },

    downloadIcon(file) {
      const index = this.files.findIndex((el) => el.url === file.url);

      const path = require.context("../assets", false, /\.svg$/);
      const icon = this.downloaded[index] ? path("./check.svg") : path("./arrow.svg");
      return icon;
    },

    icon(extension) {
      switch (extension) {
        case "png":
          return "png.svg";

        case "mp4":
          return "mp4.svg";

        case "pdf":
          return "pdf.svg";

        case "svg":
          return "svg.svg";

        case "jpg":
          return "jpg.svg";

        case "jpeg":
          return "jpeg.svg";

        case "ppt":
          return "ppt.svg";

        case "pptx":
          return "ppt.svg";

        case "xls":
          return "xlsx.svg";

        case "xlsx":
          return "xlsx.svg";

        case "word":
          return "word.svg";

        case "docs":
          return "word.svg";

        case "doc":
          return "word.svg";

        case "rtf":
          return "word.svg";

        case "txt":
          return "word.svg";

        case "dwg":
          return "dwg.svg";

        default:
          return "any.svg";
      }
    },
  },
};
</script>

<style scoped>
.file {
  background: #f1f4f7;
  border-radius: 5px 0 0 5px;
  box-shadow: 5px 6px 6px rgb(0 0 0 / 22%);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  text-transform: uppercase;
  /* margin-right: 20px; */
}

.file-size {
  opacity: 0.5;
  margin-top: 5px;
}

.file-name {
  word-break: break-all;
  color: #636971;
  font-size: 1.2em;
  font-weight: 900;
}

.file-name-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 80%;
}

.proprietary {
  margin: 0 auto;
  font-size: 20px;
}

.download-button {
  /* height: 82px; */
  margin: 0;
  background-color: #de4242;
  border-radius: 0 5px 5px 0;
}

.not-found {
  width: 100%;
  max-height: 100%;
  margin: auto;
}

.not-found-container {
  height: 89%;
}

.download-all-text {
  color: #de4242;
  line-height: 3rem;
}

#files-and-buttons {
  max-height: 100%;
  overflow: auto;
  margin-bottom: 10px;
}

.pointer-cursor {
  cursor: pointer;
}

.disable-click {
  color: #636971;
  pointer-events: none;
}

.disable-click h3 {
  color: #636971;
}

.user-select-none {
  user-select: none;
}
</style>
