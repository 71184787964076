<template>
  <Home
    :loading="loading"
    :isInvalid="isInvalid"
    :createDate="createDate"
    :by="by"
    :files="files"
  />
</template>

<script>
import Home from "@/components/Home.vue";
import { mapState, mapActions } from "vuex";
import axios from "axios";

const images = require.context("@/assets/signatures/", false, /\.gif$/);

export default {
  components: {
    Home,
  },

  props: {},

  data() {
    return {
      loading: false,
      isInvalid: false,
      createDate: "",
      by: "",
      files: {},
    };
  },

  computed: {
    ...mapState(["falconiLogo", "markers"]),
  },

  watch: {},

  created() {
    this.loading = true;

    Promise.all([this.getFilesFromOC1(), this.getDataFromFalconi(), this.saveIpDetails()]).then(
      () => {
        this.loading = false;
      }
    );
  },

  mounted() {},

  methods: {
    ...mapActions(["getDataFromFalconi", "getIpDetails"]),

    signature(file) {
      return images(`./${file}.gif`);
    },

    getFilesFromOC1() {
      const url = `${process.env.VUE_APP_FB_OUTERCLOUD_API}/download-link/get`;
      // const url = `https://api.outer.cloud/api/download-link/get`;

      return axios
        .post(url, {
          id: this.$route.params.id,
        })
        .then((response) => {
          if (response.data.error) {
            this.isInvalid = true;
            return true;
          }

          this.createDate = response.data.createDate;
          this.by = this.signature(response.data.by);
          this.files = response.data.files;

          return true;
        })
        .catch((error) => {
          console.error(error);
          this.isInvalid = true;
        });
    },

    saveIpDetails() {
      const ocLinkViewUrl = `${process.env.VUE_APP_FB_OUTERCLOUD_API}/v2/download-links/add-view`;

      return this.getIpDetails()
        .then((ipDetails) =>
          axios
            .post(ocLinkViewUrl, {
              ipDetails,
              linkId: this.$route.params.id,
            })
            .catch((error) => console.error(error))
        )
        .catch((error) => console.error(error));
    },
  },
};
</script>

<style lang="scss" scoped></style>
